

















































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api";

@Component
export default class ForgotPassword extends Vue {
  email = "";
  isSuccess = false;
  title = "Passwort vergessen";
  description =
    "Bitte geben Sie Ihre Email- Addresse ein. Wir senden Ihnen dann Instruktionen zu, wie Sie Ihr Passwort wiederherstellen können.";
  result = "";

  forgotPassword() {
    api
      .forgotPassword(this.email)
      .then((res) => {
        if (res.status === 200) {
          this.isSuccess = true;
          this.title = "Email verschickt!";
          this.description =
            "Wir haben Ihnen eine Email mit Instruktionen zugesandt. Bitte schauen Sie auch im Spamordner, falls Sie keine Email bekommen haben.";
        } else {
          this.result =
            "✘ Fehler! Leider konnten wir Ihr Passwort nicht zurücksetzen. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.";
        }
      })
      .catch((err) => {
        this.result =
          "✘ Fehler! Leider konnten wir Ihr Passwort nicht zurücksetzen. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.";
      });
  }

  noActionFormSubmit(event: Event) {
    event.preventDefault();
  }
}
